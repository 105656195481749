import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Firebase from "./Firebase/Firebase";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const MyForm = styled.form`
  display: flex;
  flex-direction: column;
  & .MuiTextField-root {
    margin: 10px 0;
    width: 25ch;
  },
`;

const NewClient = (props: Props) => {
  useEffect(() => {
    props.setAppBarTitle("Neuer Lieferant");
  }, [props]);

  let firebase = Firebase.getInstance();

  let [firstname, setFirstname] = useState("");
  let [lastname, setLastname] = useState("");
  let [address, setAddress] = useState("");
  let [city, setCity] = useState("");
  let [email, setEmail] = useState("");
  let [phone1, setPhone1] = useState("");
  let [phone2, setPhone2] = useState("");

  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);

  const onSubmit = () => {
    if (firebase === null) {
      console.log("firebase is null");
      return;
    } else {
      firebase
        .doCreateNewClient(
          firstname,
          lastname,
          address,
          city,
          email,
          phone1,
          phone2
        )
        .then(() => setShowSuccess(true))
        .catch(() => setShowError(true));
    }
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Erfasse einen neuen Lieferanten</Typography>
        <MyForm noValidate autoComplete="off">
          <TextField
            label="Vorname"
            required
            type={"text"}
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            autoComplete="off"
            name="search"
          />
          <TextField
            label="Nachname"
            required
            type={"text"}
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <TextField
            label="Adresse"
            type={"text"}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            label="Ort"
            type={"text"}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            label="E-mail"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Telefon 1"
            type={"text"}
            value={phone1}
            onChange={(e) => setPhone1(e.target.value)}
          />
          <TextField
            label="Telefon 2"
            type={"text"}
            value={phone2}
            onChange={(e) => setPhone2(e.target.value)}
          />
          <Button
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
          >
            Erfassen
          </Button>
        </MyForm>
      </Wrapper>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Lieferant erfolgreich erfasst
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Lieferant konnte nicht erfasst werden
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default NewClient;
