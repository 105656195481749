export type ProductModel = {
  id?: string;
  title?: string;
  description?: string;
  externalPrice?: number;
  internalPrice?: number;
  validUntil?: Date | null;
  owner?: string;
  pictures?: string[];
  urls?: string[];
  size?: string;
  category?: string;
  brand?: string;
  state?: ProductStates;
  location?: ProductLocation;
  websiteHighlight?: boolean;
  hideOnWebsite?: boolean;
  notes?: string;
};

export enum ProductStates {
  REGISTERED = "REGISTERED",
  STORE = "STORE",
  SOLD = "SOLD",
  PAID_OUT = "PAID_OUT",
  CHARITY = "CHARITY",
  RETURNED = "RETURNED",
}

export const getAllProductStates = () => {
  return [
    ProductStates.REGISTERED,
    ProductStates.STORE,
    ProductStates.SOLD,
    ProductStates.PAID_OUT,
    ProductStates.CHARITY,
    ProductStates.RETURNED,
  ];
};

export enum ProductLocation {
  STORE = "STORE",
  STORAGE1 = "STORAGE1",
}

export const getAllProductLocations = () => {
  return [ProductLocation.STORE, ProductLocation.STORAGE1];
};
