import {
  ActionTypes,
  ChangedClient,
  ClientState,
  DeleteClient,
  ErrorClients,
  FetchedClients,
  FetchedSingleClient,
  LoadingClients,
} from "./types";

const initialState: ClientState = {
  clients: [],
  initialFetch: false,
  initialFetchSingle: [],
  loading: false,
};

const clientsReducer = () => {
  return (
    state = initialState,
    action:
      | FetchedClients
      | FetchedSingleClient
      | DeleteClient
      | ErrorClients
      | LoadingClients
      | ChangedClient
  ) => {
    switch (action.type) {
      case ActionTypes.LOADING_CLIENTS:
        return { ...state, loading: action.loading };
      case ActionTypes.FETCHED_CLIENTS:
        return {
          ...state,
          clients: action.clients,
          initialFetch: action.initialFetch,
          initialFetchSingle: action.clients.map((c) => c.id),
          loading: action.loading,
        };
      case ActionTypes.FETCHED_SINGLE_CLIENT:
        return {
          ...state,
          clients: [
            ...state.clients.filter((c) => c.id !== action.client.id),
            action.client,
          ],
          initialFetchSingle: [
            ...state.initialFetchSingle.filter((c) => c !== action.client.id),
            action.client.id,
          ],
          loading: action.loading,
        };
      case ActionTypes.DELETE_CLIENT:
        return {
          ...state,
          clients: state.clients.filter((c) => c.id !== action.id),
          loading: action.loading,
        };
      case ActionTypes.ERROR_CLIENTS:
        return { ...state, error: action.error, loading: action.loading };
      case ActionTypes.CHANGED_CLIENT:
        return {
          ...state,
          clients: [
            ...state.clients.map((c) => {
              if (c.id !== action.client.id) return c;
              else return action.client;
            }),
          ],
          loading: action.loading,
        };
      default:
        return state;
    }
  };
};

export default clientsReducer;
