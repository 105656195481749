import { ClientModel } from "../../models/Client";

export enum ActionTypes {
  LOADING_CLIENTS = "LOADING_CLIENTS",
  FETCHED_CLIENTS = "FETCHED_CLIENTS",
  FETCHED_SINGLE_CLIENT = "FETCHED_SINGLE_CLIENT",
  DELETE_CLIENT = "DELETE_CLIENT",
  ERROR_CLIENTS = "ERROR_CLIENTS",
  CHANGED_CLIENT = "CHANGED_CLIENT",
}

/**
 * Action Types
 */
export type LoadingClients = {
  type: ActionTypes.LOADING_CLIENTS;
  loading: boolean;
};
export type FetchedClients = {
  clients: ClientModel[];
  type: ActionTypes.FETCHED_CLIENTS;
  initialFetch: boolean;
  loading: boolean;
};
export type FetchedSingleClient = {
  client: ClientModel;
  type: ActionTypes.FETCHED_SINGLE_CLIENT;
  loading: boolean;
};
export type DeleteClient = {
  type: ActionTypes.DELETE_CLIENT;
  id: string;
  loading: boolean;
};
export type ErrorClients = {
  type: ActionTypes.ERROR_CLIENTS;
  error: string;
  loading: boolean;
};
export type ChangedClient = {
  client: ClientModel;
  type: ActionTypes.CHANGED_CLIENT;
  loading: boolean;
};

/**
 * State Type
 */
export type ClientState = {
  clients: ClientModel[];
  initialFetch: boolean;
  initialFetchSingle: string[];
  loading: boolean;
  error?: string;
};
