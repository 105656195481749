import { Brand } from "../../models/Brand";

export enum ActionTypes {
  LOADING_BRANDS = "LOADING_BRANDS",
  FETCHED_BRANDS = "FETCHED_BRANDS",
  ERROR_BRANDS = "ERROR_BRANDS",
  DELETE_BRAND = "DELETE_BRAND",
  ADD_BRAND = "ADD_BRAND",
}

/**
 * Action Types
 */
export type LoadingBrands = {
  type: ActionTypes.LOADING_BRANDS;
  loading: boolean;
};
export type FetchedBrands = {
  brands: Brand[];
  type: ActionTypes.FETCHED_BRANDS;
  loading: boolean;
  initialFetch: boolean;
};
export type ErrorBrands = {
  type: ActionTypes.ERROR_BRANDS;
  error: string;
  loading: boolean;
};
export type DeleteBrand = {
  type: ActionTypes.DELETE_BRAND;
  id: string;
  loading: boolean;
};
export type AddBrand = {
  type: ActionTypes.ADD_BRAND;
  brand: Brand;
  loading: boolean;
};

/**
 * State Type
 */
export type BrandState = {
  brands: Brand[];
  loading: boolean;
  initialFetch: boolean;
  error?: string;
};
