import { ThunkDispatch } from "redux-thunk";
import {
  ActionTypes,
  ChangedClient,
  DeleteClient,
  ErrorClients,
  FetchedClients,
  FetchedSingleClient,
  LoadingClients,
} from "./types";
import { ClientModel } from "../../models/Client";
import Firebase from "../../components/Firebase/Firebase";

export const fetchClients = (): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedClients | ErrorClients | LoadingClients
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_CLIENTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetAllClients();
      if (!result) throw new Error("could not get clients");
      let clients: ClientModel[] = [];
      if (!result.empty)
        result.forEach((doc: any) => {
          clients.push(doc.data());
          clients[clients.length - 1].id = doc.id;
        });
      dispatch({
        type: ActionTypes.FETCHED_CLIENTS,
        clients,
        initialFetch: true,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_CLIENTS,
        error,
        loading: false,
      });
    }
  };
};

export const fetchSpecificClient = (id: string): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedSingleClient | ErrorClients | LoadingClients
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_CLIENTS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      const result = await firebase.doGetSpecificClient(id);
      if (!result) throw new Error("could not get client: " + id);
      if (!result.exists) throw new Error("client does not exist: " + id);
      let data = result.data();
      if (data === undefined) throw new Error("no client data: " + id);
      let client: ClientModel = data;
      client.id = result.id;
      dispatch({
        type: ActionTypes.FETCHED_SINGLE_CLIENT,
        client,
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_CLIENTS,
        error,
        loading: false,
      });
    }
  };
};

export const deleteClient = (id: string): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      DeleteClient | ErrorClients | LoadingClients
    >
  ) => {
    dispatch({
      type: ActionTypes.DELETE_CLIENT,
      id,
      loading: false,
    });
  };
};

export const changeClient = (changedClient: ClientModel): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      ChangedClient | ErrorClients | LoadingClients
    >
  ) => {
    dispatch({
      type: ActionTypes.CHANGED_CLIENT,
      client: changedClient,
      loading: false,
    });
  };
};
