import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { RouteComponentProps, withRouter } from "react-router";
import { ClientModel } from "../models/Client";
import Firebase from "./Firebase/Firebase";
import { changeClient, fetchSpecificClient } from "../redux/clients/actions";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  setAppBarTitle: (title: string) => void;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const MyForm = styled.form`
  display: flex;
  flex-direction: column;
  & .MuiTextField-root {
    margin: 10px 0;
    width: 25ch;
  },
`;

const EditClient = (props: Props) => {
  useEffect(() => {
    props.setAppBarTitle("Lieferant bearbeiten");
  }, [props]);
  const id = useMemo(() => props.match.params.id, [props.match.params.id]);

  const dispatch = useDispatch();

  const { clients, loading: loadingC, initialFetchSingle: initC } = useAppState(
    (state) => state.clients
  );

  const client = useMemo(() => clients.find((c) => c.id === id), [clients, id]);

  useEffect(() => {
    if (!loadingC && !initC.includes(id)) dispatch(fetchSpecificClient(id));
  });

  let firebase = Firebase.getInstance();

  let [firstname, setFirstname] = useState("");
  let [lastname, setLastname] = useState("");
  let [address, setAddress] = useState("");
  let [city, setCity] = useState("");
  let [email, setEmail] = useState("");
  let [phone1, setPhone1] = useState("");
  let [phone2, setPhone2] = useState("");

  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);

  useEffect(() => {
    if (client) {
      if (client.firstname) setFirstname(client.firstname);
      if (client.lastname) setLastname(client.lastname);
      if (client.address) setAddress(client.address);
      if (client.city) setCity(client.city);
      if (client.email) setEmail(client.email);
      if (client.phone1) setPhone1(client.phone1);
      if (client.phone2) setPhone2(client.phone2);
    }
  }, [client]);

  const onSubmit = useCallback(() => {
    const changedClient: ClientModel = {
      id,
      firstname,
      lastname,
      address,
      city,
      email,
      phone1,
      phone2,
    };
    firebase
      .doEditClient(changedClient)
      .then(() => {
        setShowSuccess(true);
        dispatch(changeClient(changedClient));
      })
      .catch(() => setShowError(true));
  }, [
    id,
    firstname,
    lastname,
    address,
    city,
    email,
    phone1,
    phone2,
    dispatch,
    firebase,
  ]);

  if (!client) return null;

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">
          Bearbeite {client.firstname} {client.lastname}
        </Typography>
        <MyForm noValidate autoComplete="off">
          <TextField
            label="Vorname"
            required
            type={"text"}
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            autoComplete="off"
            name="search"
          />
          <TextField
            label="Nachname"
            required
            type={"text"}
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <TextField
            label="Adresse"
            type={"text"}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            label="Ort"
            type={"text"}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <TextField
            label="E-mail"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Telefon 1"
            type={"text"}
            value={phone1}
            onChange={(e) => setPhone1(e.target.value)}
          />
          <TextField
            label="Telefon 2"
            type={"text"}
            value={phone2}
            onChange={(e) => setPhone2(e.target.value)}
          />
          <Button
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            Bearbeiten
          </Button>
        </MyForm>
      </Wrapper>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Lieferant erfolgreich bearbeitet
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Lieferant konnte nicht bearbeitet werden
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default withRouter(EditClient);
