import { ThunkDispatch } from "redux-thunk";
import {
  ActionTypes,
  AddCategory,
  DeleteCategory,
  ErrorCategories,
  FetchedCategories,
  LoadingCategories,
} from "./types";
import Firebase from "../../components/Firebase/Firebase";
import { Category } from "../../models/Category";

export const fetchCategories = (): any => {
  return async (
    dispatch: ThunkDispatch<
      {},
      {},
      FetchedCategories | ErrorCategories | LoadingCategories
    >
  ) => {
    dispatch({
      type: ActionTypes.LOADING_CATEGORIES,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      firebase?.doGetAllCategories().then((result) => {
        if (!result) throw new Error("could not get categories");
        let categories: Category[] = [];
        if (!result.empty)
          result.forEach((doc: any) => {
            categories.push({ name: doc.data().name, id: doc.id });
          });
        dispatch({
          type: ActionTypes.FETCHED_CATEGORIES,
          categories,
          initialFetch: true,
          loading: false,
        });
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_CATEGORIES,
        error,
        loading: false,
      });
    }
  };
};

export const deleteCategory = (id: string): any => {
  return async (dispatch: ThunkDispatch<{}, {}, DeleteCategory>) => {
    dispatch({
      type: ActionTypes.DELETE_CATEGORY,
      id,
      loading: false,
    });
  };
};

export const addCategory = (category: Category): any => {
  return async (dispatch: ThunkDispatch<{}, {}, AddCategory>) => {
    dispatch({
      type: ActionTypes.ADD_CATEGORY,
      category,
      loading: false,
    });
  };
};
