import {
  ActionTypes,
  AddCategory,
  CategoryState,
  DeleteCategory,
  ErrorCategories,
  FetchedCategories,
  LoadingCategories,
} from "./types";
import { compareStrings } from "../../helper/string-compare";

const initialState: CategoryState = {
  categories: [],
  initialFetch: false,
  loading: false,
};

const categoriesReducer = () => {
  return (
    state = initialState,
    action:
      | FetchedCategories
      | ErrorCategories
      | LoadingCategories
      | DeleteCategory
      | AddCategory
  ) => {
    switch (action.type) {
      case ActionTypes.LOADING_CATEGORIES:
        return { ...state, loading: action.loading };
      case ActionTypes.FETCHED_CATEGORIES:
        return {
          ...state,
          categories: action.categories,
          loading: action.loading,
          initialFetch: true,
        };
      case ActionTypes.ERROR_CATEGORIES:
        return { ...state, error: action.error, loading: action.loading };
      case ActionTypes.DELETE_CATEGORY:
        return {
          ...state,
          categories: state.categories.filter((c) => c.id !== action.id),
          loading: action.loading,
        };
      case ActionTypes.ADD_CATEGORY:
        return {
          ...state,
          categories: [...state.categories, action.category].sort((a, b) =>
            compareStrings(a.name ? a.name : "", b.name ? b.name : "")
          ),
          loading: action.loading,
        };
      default:
        return state;
    }
  };
};

export default categoriesReducer;
