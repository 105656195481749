import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

import DeleteIcon from "@material-ui/icons/Delete";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import ConfirmationDialog from "./ConfirmationDialog";
import Firebase from "./Firebase/Firebase";
import { useDispatch } from "react-redux";
import { fetchBrands, deleteBrand, addBrand } from "../redux/brands/actions";
import {
  deleteCategory,
  fetchCategories,
  addCategory,
} from "../redux/categories/actions";
import useAppState from "../redux/useAppState";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const CategoryBrand = (props: Props) => {
  let firebase = Firebase.getInstance();
  const dispatch = useDispatch();

  const {
    categories,
    loading: loadingCategories,
    error: errorCategories,
    initialFetch: initialFetchCategories,
  } = useAppState((state) => state.categories);
  const {
    brands,
    loading: loadingBrands,
    error: errorBrands,
    initialFetch: initialFetchBrands,
  } = useAppState((state) => state.brands);

  let [newCategory, setNewCategory] = useState<string>("");
  let [newBrand, setNewBrand] = useState<string>("");
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [addBrandModal, setAddBrandModal] = useState(false);
  const [searchCategoryString, setSearchCategoryString] = useState("");
  const [searchBrandString, setSearchBrandString] = useState("");
  const [snackbarText, setSnackbarText] = useState("");
  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [typeOfDeletion, setTypeOfDeletion] = useState<
    "" | "brand" | "category"
  >("");

  const filteredCategories = useMemo(() => {
    return categories.filter((c) => {
      if (searchCategoryString === "") return true;
      return c.name
        ?.toLocaleLowerCase()
        .includes(searchCategoryString.toLocaleLowerCase());
    });
  }, [categories, searchCategoryString]);

  const filteredBrands = useMemo(() => {
    return brands.filter((b) => {
      if (searchBrandString === "") return true;
      return b.name
        ?.toLocaleLowerCase()
        .includes(searchBrandString.toLocaleLowerCase());
    });
  }, [brands, searchBrandString]);

  useEffect(() => {
    if (
      (!loadingCategories && errorCategories === "") ||
      (!loadingCategories && !initialFetchCategories)
    )
      dispatch(fetchCategories());
  }, [dispatch, loadingCategories, errorCategories, initialFetchCategories]);

  useEffect(() => {
    if (
      (!loadingBrands && errorBrands === "") ||
      (!loadingBrands && !initialFetchBrands)
    )
      dispatch(fetchBrands());
  }, [dispatch, loadingBrands, errorBrands, initialFetchBrands]);

  useEffect(() => {
    props.setAppBarTitle("Kategorien und Marken");
  }, [props]);

  const handleNewCategory = useCallback(() => {
    firebase
      .doAddCategory(newCategory)
      .then((doc) => {
        setSnackbarText("Kategorie hinzugefügt");
        setShowSuccess(true);
        setAddCategoryModal(false);
        dispatch(addCategory({ id: doc.id, name: newCategory }));
      })
      .catch(() => {
        setSnackbarText("Kategorie konnte nicht hinzugefügt werden");
        setShowError(true);
      });
  }, [dispatch, firebase, newCategory]);

  const handleCategoryDelete = useCallback(
    (id: string) => {
      firebase
        .doDeleteCategory(id)
        .then(() => {
          setSnackbarText("Kategorie gelöscht");
          setShowSuccess(true);
          dispatch(deleteCategory(id));
          setNewCategory("");
        })
        .catch(() => {
          setSnackbarText("Kategorie konnte nicht gelöscht werden");
          setShowError(true);
        });
    },
    [dispatch, firebase]
  );

  const handleNewBrand = useCallback(() => {
    firebase
      .doAddBrand(newBrand)
      .then((doc) => {
        setSnackbarText("Marke hinzugefügt");
        setShowSuccess(true);
        setAddBrandModal(false);
        setNewBrand("");
        dispatch(addBrand({ name: newBrand, id: doc.id }));
      })
      .catch(() => {
        setSnackbarText("Marke konnte nicht hinzugefügt werden");
        setShowError(true);
      });
  }, [dispatch, firebase, newBrand]);

  const handleBrandDelete = useCallback(
    (id: string) => {
      firebase
        .doDeleteBrand(id)
        .then(() => {
          setSnackbarText("Marke gelöscht");
          setShowSuccess(true);
          dispatch(deleteBrand(id));
        })
        .catch(() => {
          setSnackbarText("Marke konnte nicht gelöscht werden");
          setShowError(true);
        });
    },
    [dispatch, firebase]
  );

  const handleDeleteConfirmation = useCallback(
    (id: string, type: "brand" | "category") => {
      setIdToDelete(id);
      setTypeOfDeletion(type);
      setShowDeleteConfirmation(true);
    },
    []
  );

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Liste aller Kategorien</Typography>
        <TextField
          id="standard-search"
          label="Suchen"
          type="search"
          value={searchCategoryString}
          onChange={(e) => setSearchCategoryString(e.target.value)}
        />
        <List>
          {filteredCategories.map((value, index) => (
            <ListItem key={String("category - " + index)}>
              <ListItemText primary={value.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    if (value.id)
                      handleDeleteConfirmation(value.id, "category");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {filteredBrands.length === 0 && (
          <Typography variant="body2">Keine Kategorien vorhanden</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddCategoryModal(true)}
          style={{ marginTop: 10, marginBottom: 30 }}
        >
          Kategorie hinzufügen
        </Button>
        <Typography variant="h6">Liste aller Marken</Typography>
        <TextField
          id="standard-search"
          label="Suchen"
          type="search"
          value={searchBrandString}
          onChange={(e) => setSearchBrandString(e.target.value)}
        />
        <List>
          {filteredBrands.map((value, index) => (
            <ListItem key={String("brand - " + index)}>
              <ListItemText primary={value.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    if (value.id) handleDeleteConfirmation(value.id, "brand");
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {filteredBrands.length === 0 && (
          <Typography variant="body2">Keine Marken vorhanden</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddBrandModal(true)}
          style={{ marginTop: 10 }}
        >
          Marke hinzufügen
        </Button>
      </Wrapper>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={addCategoryModal}
        {...props}
      >
        <DialogTitle>Kategorie hinzufügen</DialogTitle>
        <DialogContent dividers>
          <TextField
            id="new-category-input"
            label="Kategorie"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setAddCategoryModal(false);
              setNewCategory("");
            }}
          >
            Abbrechen
          </Button>
          <Button onClick={handleNewCategory} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={addBrandModal}
        {...props}
      >
        <DialogTitle>Marke hinzufügen</DialogTitle>
        <DialogContent dividers>
          <TextField
            id="new-brand-input"
            label="Marke"
            value={newBrand}
            onChange={(e) => setNewBrand(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setAddBrandModal(false);
              setNewBrand("");
            }}
          >
            Abbrechen
          </Button>
          <Button onClick={handleNewBrand} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          {snackbarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          {snackbarText}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showDeleteConfirmation}
        title={"Löschen bestätigen"}
        onClose={(confirmation: boolean) => {
          if (confirmation && typeOfDeletion === "brand")
            handleBrandDelete(idToDelete);
          if (confirmation && typeOfDeletion === "category")
            handleCategoryDelete(idToDelete);
          setShowDeleteConfirmation(false);
        }}
        confirmText={"Löschen"}
      >
        Bist du dir sicher, dass du diesen Eintrag löschen möchtest?
      </ConfirmationDialog>
    </React.Fragment>
  );
};

export default CategoryBrand;
