import React, {useState} from "react";
import {Button, CircularProgress, Paper, TextField, Typography,} from "@material-ui/core";
import styled from "styled-components";
import {Redirect, RouteComponentProps, withRouter} from "react-router";
import Firebase from "./Firebase/Firebase";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Container = styled(Paper)`
  padding: 30px;
`;

const MyForm = styled.form`
  display: flex;
  flex-direction: column;
  & .MuiTextField-root {
    margin: 10px 0;
    width: 25ch;
  },
`;

const Login = (props: RouteComponentProps) => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  let firebase = Firebase.getInstance();

  const onSubmit = () => {
    if (firebase === null) {
      console.log("firebase is null");
      return;
    } else {
      firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(() => props.history.push("/app"))
        .catch((reason) => console.log(reason.message));
    }
  };

  return firebase === null ? (
    <CircularProgress />
  ) : firebase.isLoggedIn() ? (
    <Redirect to={"/app"} />
  ) : (
    <Wrapper>
      <Container>
        <Typography variant="h4">Schuhstall Intern</Typography>
        <MyForm noValidate autoComplete="off">
          <TextField
            label="E-Mail"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Passwort"
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
          >
            Login
          </Button>
        </MyForm>
      </Container>
    </Wrapper>
  );
};

export default withRouter(Login);
