import { ThunkDispatch } from "redux-thunk";
import {
  ActionTypes,
  AddBrand,
  DeleteBrand,
  ErrorBrands,
  FetchedBrands,
  LoadingBrands,
} from "./types";
import Firebase from "../../components/Firebase/Firebase";
import { Brand } from "../../models/Brand";

export const fetchBrands = (): any => {
  return async (
    dispatch: ThunkDispatch<{}, {}, FetchedBrands | ErrorBrands | LoadingBrands>
  ) => {
    dispatch({
      type: ActionTypes.LOADING_BRANDS,
      loading: true,
    });

    const firebase = Firebase.getInstance();

    try {
      if (!firebase) throw new Error("firebase is null");
      firebase?.doGetAllBrands().then((result) => {
        if (!result) throw new Error("could not get brands");
        let brands: Brand[] = [];
        if (!result.empty)
          result.forEach((doc: any) => {
            brands.push({ name: doc.data().name, id: doc.id });
          });
        dispatch({
          type: ActionTypes.FETCHED_BRANDS,
          brands,
          initialFetch: true,
          loading: false,
        });
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR_BRANDS,
        error,
        loading: false,
      });
    }
  };
};

export const deleteBrand = (id: string): any => {
  return async (dispatch: ThunkDispatch<{}, {}, DeleteBrand>) => {
    dispatch({
      type: ActionTypes.DELETE_BRAND,
      id,
      loading: false,
    });
  };
};

export const addBrand = (brand: Brand): any => {
  return async (dispatch: ThunkDispatch<{}, {}, AddBrand>) => {
    dispatch({
      type: ActionTypes.ADD_BRAND,
      brand,
      loading: false,
    });
  };
};
