import React, { useCallback, useEffect, useMemo } from "react";
import { Card, Chip, List, Typography } from "@material-ui/core";
import { ProductStates } from "../models/Product";
import { undefinedRemoverFromNumber } from "../helper/undefinedRemover";
import ProductCard from "../views/ProductCard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import styled from "styled-components";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";
import {
  fetchPayOutProducts,
  fetchReturnProducts,
} from "../redux/products/actions";

const Wrapper = styled.div`
  margin: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const MyCard = styled(Card)`
  padding: 10px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const Dashboard = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    props.setAppBarTitle("Übersicht");
  }, [props]);

  const {
    products,
    productsToReturn: returnProducts,
    initialFetchPayout,
    initialFetchReturn,
    loading,
  } = useAppState((state) => state.products);

  const payOutProducts = useMemo(
    () => products.filter((p) => p.state === ProductStates.SOLD),
    [products]
  );

  useEffect(() => {
    if (!loading && !initialFetchPayout) dispatch(fetchPayOutProducts());
  }, [loading, initialFetchPayout, dispatch]);

  useEffect(() => {
    if (!loading && !initialFetchReturn) dispatch(fetchReturnProducts());
  }, [loading, initialFetchReturn, dispatch]);

  const getTotalPayOutMoney = useCallback(() => {
    let total = 0;
    payOutProducts.forEach((p) => {
      total += undefinedRemoverFromNumber(p.internalPrice);
    });
    return total;
  }, [payOutProducts]);

  return (
    <Wrapper>
      <MyCard>
        <Typography variant="h6">Produkte retournieren</Typography>
        <List>
          {returnProducts.map((value, index) => (
            <ProductCard key={index} product={value} />
          ))}
          {returnProducts.length === 0 && (
            <Typography variant="body2">
              Keine zu retournierenden Produkte vorhanden
            </Typography>
          )}
        </List>
      </MyCard>
      <MyCard>
        <Typography variant="h6">Produkte ausbezahlen</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="overline" style={{ marginRight: 20 }}>
            Total auszubezahlen:{" "}
          </Typography>
          <Chip
            icon={<AttachMoneyIcon />}
            size="small"
            label={`${getTotalPayOutMoney().toFixed(2)} CHF`}
          />
        </div>
        <List>
          {payOutProducts.map((value, index) => (
            <ProductCard key={index} product={value} />
          ))}
          {payOutProducts.length === 0 && (
            <Typography variant="body2">
              Keine auszubezahlenden Produkte vorhanden
            </Typography>
          )}
        </List>
      </MyCard>
    </Wrapper>
  );
};
export default Dashboard;
