export const undefinedRemoverFromString = (value: any) => {
 return value !== undefined ? value : ""
};

export const undefinedRemoverFromNumber = (value: any) => {
  return value !== undefined ? value : 0
};

export const undefinedRemoverFromArray = (value: any) => {
  return value !== undefined ? value : []
};

export const undefinedRemoverFromDate = (value: any) => {
  return value !== undefined ?  value : new Date()
};