import React from "react";
import {
  Container,
  ListItemText,
  TableContainer,
  Typography,
  TableBody,
  Table,
  TableRow,
  TableCell,
} from "@material-ui/core";
import styled from "styled-components";
import { ClientModel } from "../../models/Client";
import { ProductModel } from "../../models/Product";
import { format } from "date-fns";

type Props = {
  clients: ClientModel[];
  client: string;
  product: ProductModel;
};

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const ConfirmNewProduct = (props: Props) => {
  const getSelectedClient = () => {
    for (let i = 0; i < props.clients.length; i++) {
      if (props.clients[i].id === props.client) {
        return props.clients[i];
      }
    }
    return {};
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Lieferant</Typography>
        <ListItemText
          primary={
            getSelectedClient().lastname + " " + getSelectedClient().firstname
          }
          secondary={
            getSelectedClient().address + ", " + getSelectedClient().city
          }
        />
        <Typography variant="h6">Produkt</Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Produkt Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{props.product.title}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Beschreibung</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {props.product.description}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Produkt Kategorie</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {props.product.category}
                  </Typography>
                </TableCell>
              </TableRow>
              {props.product.category === "Schuhe" && (
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">Grösse</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {props.product.size}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Marke</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {props.product.brand}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Kundenpreis</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    CHF {props.product.externalPrice?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Lieferantenpreis</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    CHF {props.product.internalPrice?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Gültig bis</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {props.product.validUntil
                      ? format(props.product.validUntil, "dd/MM/yyyy")
                      : "unbekannt"}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Wrapper>
    </React.Fragment>
  );
};

export default ConfirmNewProduct;
