import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  List,
  Snackbar,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { CLIENTS } from "../constants/routes";
import ConfirmationDialog from "./ConfirmationDialog";
import ProductCard from "../views/ProductCard";
import Firebase from "./Firebase/Firebase";
import useAppState from "../redux/useAppState";
import { deleteClient, fetchSpecificClient } from "../redux/clients/actions";
import { useDispatch } from "react-redux";
import { fetchClientsProducts } from "../redux/products/actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const ClientInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;

const SubContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  setAppBarTitle: (title: string) => void;
}

const Client = (props: Props) => {
  let firebase = Firebase.getInstance();
  const dispatch = useDispatch();

  const id = props.match.params.id;

  const {
    products,
    loading: loadingP,
    initialFetchClients: initialFetchP,
  } = useAppState((state) => state.products);

  const {
    clients,
    loading: loadingC,
    initialFetchSingle: initialFetchC,
  } = useAppState((state) => state.clients);

  const productsOfClient = useMemo(
    () => products.filter((p) => p.owner === id),
    [products, id]
  );

  const client = useMemo(() => clients.find((c) => c.id === id), [clients, id]);

  useEffect(() => {
    if (!loadingP && !initialFetchP.includes(id))
      dispatch(fetchClientsProducts(id));
  }, [loadingP, initialFetchP, dispatch, id]);

  useEffect(() => {
    if (!loadingC && !initialFetchC.includes(id))
      dispatch(fetchSpecificClient(id));
  }, [loadingC, dispatch, id, initialFetchC]);

  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);

  let [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    props.setAppBarTitle("Lieferant");
  }, [props]);

  const onDeleteClient = useCallback(() => {
    firebase
      .doDeleteClient(id)
      .then(() => {
        setShowSuccess(true);
        dispatch(deleteClient(id));
        props.history.push(CLIENTS);
      })
      .catch(() => setShowError(true));
  }, [dispatch, firebase, id, props.history]);

  const handleConfirmation = useCallback(
    (confirmation: boolean) => {
      setShowDeleteConfirmation(false);
      if (confirmation) onDeleteClient();
    },
    [onDeleteClient]
  );

  const handleEdit = useCallback(() => {
    props.history.push(`${CLIENTS}/${id}/edit`);
  }, [id, props.history]);

  if (!client) return null;

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h5">
          {client.firstname + " " + client.lastname}
        </Typography>
        <ClientInfoContainer>
          <SubContainer>
            <span>{client.address}</span>
            <span>{client.city}</span>
          </SubContainer>
          <SubContainer>
            <span>{client.email}</span>
            <span>{client.phone1}</span>
            <span>{client.phone2}</span>
          </SubContainer>
        </ClientInfoContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 50,
          }}
        >
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={handleEdit}
          >
            Bearbeiten
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            style={{ marginLeft: 10 }}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Löschen
          </Button>
        </div>
        <Typography variant="h6">Produkte</Typography>
        <List>
          {productsOfClient.map((value, index) => (
            <ProductCard key={index} product={value} />
          ))}
        </List>
      </Wrapper>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Lieferant erfolgreich gelöscht
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Lieferant konnte nicht gelöscht werden
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showDeleteConfirmation}
        title={"Löschen bestätigen"}
        onClose={(confirmation: boolean) => handleConfirmation(confirmation)}
        confirmText={"Löschen"}
      >
        Bist du dir sicher, dass du diesen Eintrag löschen möchtest?
      </ConfirmationDialog>
    </React.Fragment>
  );
};
export default withRouter(Client);
