import React from "react";
import { Redirect } from "react-router";
import LoggedIn from "./LoggedIn";
import { CircularProgress } from "@material-ui/core";
import Firebase from "./Firebase/Firebase";

const LoggedInGuard = () => {
  let firebase = Firebase.getInstance();

  return firebase === null ? (
    <CircularProgress />
  ) : firebase.isLoggedIn() ? (
    <LoggedIn />
  ) : (
    <Redirect to={"/login"} />
  );
};
export default LoggedInGuard;
