import { Category } from "../../models/Category";

export enum ActionTypes {
  LOADING_CATEGORIES = "LOADING_CATEGORIES",
  FETCHED_CATEGORIES = "FETCHED_CATEGORIES",
  ERROR_CATEGORIES = "ERROR_CATEGORIES",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  ADD_CATEGORY = "ADD_CATEGORY",
}

/**
 * Action Types
 */
export type LoadingCategories = {
  type: ActionTypes.LOADING_CATEGORIES;
  loading: boolean;
};
export type FetchedCategories = {
  categories: Category[];
  type: ActionTypes.FETCHED_CATEGORIES;
  initialFetch: boolean;
  loading: boolean;
};
export type ErrorCategories = {
  type: ActionTypes.ERROR_CATEGORIES;
  error: string;
  loading: boolean;
};
export type DeleteCategory = {
  type: ActionTypes.DELETE_CATEGORY;
  id: string;
  loading: boolean;
};

export type AddCategory = {
  type: ActionTypes.ADD_CATEGORY;
  category: Category;
  loading: boolean;
};

/**
 * State Type
 */
export type CategoryState = {
  categories: Category[];
  loading: boolean;
  initialFetch: boolean;
  error?: string;
};
