import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { ProductModel, ProductStates } from "../../models/Product";
import { firebaseConfig } from "./firebase-config";
import { ClientModel } from "../../models/Client";
import { Category } from "../../models/Category";
import { Brand } from "../../models/Brand";

class Firebase {
  private auth: firebase.auth.Auth;
  private db: firebase.firestore.Firestore;
  private storage: firebase.storage.Storage;
  private static instance = new Firebase();

  private constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  static getInstance() {
    return Firebase.instance;
  }

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doGetDisplayName = () => this.auth.currentUser?.displayName;

  isInitialized = () =>
    new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });

  isLoggedIn = () => {
    return Boolean(this.auth.currentUser);
  };

  doCreateNewClient = (
    firstname: string,
    lastname: string,
    address: string,
    city: string,
    email: string,
    phone1: string,
    phone2: string
  ) =>
    this.db
      .collection("clients")
      .add({ firstname, lastname, address, city, email, phone1, phone2 });

  doDeleteClient = (id: string) =>
    this.db.collection("clients").doc(id).delete();

  doEditClient = (client: ClientModel) =>
    this.db.collection("clients").doc(client.id).update(client);

  doRestoreClient = (client: ClientModel) =>
    this.db.collection("clients").doc(client.id).set(client);

  doGetAllClients = () => {
    return this.db.collection("clients").orderBy("lastname").get();
  };

  doGetSpecificClient = (id: string) => {
    return this.db.collection("clients").doc(id).get();
  };

  doUploadFile = (file: File, name: string) => {
    return this.storage.ref(`shoes/${name}.jpeg`).put(file);
  };

  doDeleteFile = (name: string) => {
    return this.storage.ref(`shoes/${name}.jpeg`).delete();
  };

  doGetSpecificFile = (id: string): Promise<string> => {
    return this.storage.ref(`shoes/${id}.jpeg`).getDownloadURL();
  };

  doCreateProduct = (product: ProductModel) => {
    return this.db.collection("products").add(product);
  };

  doEditProduct = (id: string, product: ProductModel) =>
    this.db.collection("products").doc(id).update(product);

  doRestoreProduct = (id: string, product: ProductModel) =>
    this.db.collection("products").doc(id).set(product);

  doDeleteProduct = (id: string) =>
    this.db.collection("products").doc(id).delete();

  doGetAllProducts = () => {
    return this.db.collection("products").get();
  };

  doGetAllLabelProducts = () => {
    return this.db
      .collection("products")
      .where("state", "==", "REGISTERED")
      .orderBy("validUntil", "desc")
      .get();
  };

  doGetAllSellableProducts = () => {
    return this.db
      .collection("products")
      .where("state", "==", "STORE")
      .orderBy("validUntil", "desc")
      .get();
  };

  doGetAllPayOutProducts = () => {
    return this.db
      .collection("products")
      .where("state", "==", "SOLD")
      .orderBy("validUntil", "desc")
      .get();
  };

  doGetAllReturnProducts = () => {
    const beginningDateObject = new Date();
    return this.db
      .collection("products")
      .where("state", "==", "STORE")
      .where("validUntil", "<", beginningDateObject)
      .orderBy("validUntil", "desc")
      .get();
  };

  doGetAllProductsOfClient = (clientId: string) => {
    return this.db
      .collection("products")
      .where("owner", "==", clientId)
      .orderBy("validUntil", "desc")
      .get();
  };

  doGetSpecificProduct = (id: string) => {
    return this.db.collection("products").doc(id).get();
  };

  doSellSpecificProduct = (id: string) => {
    return this.db
      .collection("products")
      .doc(id)
      .update({ state: ProductStates.SOLD });
  };

  doPayOutSpecificProduct = (id: string) =>
    this.db
      .collection("products")
      .doc(id)
      .update({ state: ProductStates.PAID_OUT });

  doLabelSpecificProduct = (id: string) =>
    this.db
      .collection("products")
      .doc(id)
      .update({ state: ProductStates.STORE });

  doGetAllCategories = () => {
    return this.db.collection("productCategories").orderBy("name").get();
  };

  doAddCategory = (category: string) => {
    return this.db.collection("productCategories").add({ name: category });
  };

  doDeleteCategory = (id: string) => {
    return this.db.collection("productCategories").doc(id).delete();
  };

  doRestoreCategory = (c: Category) =>
    this.db.collection("productCategories").doc(c.id).set(c);

  doGetAllBrands = () => {
    return this.db.collection("productBrands").orderBy("name").get();
  };

  doAddBrand = (brand: string) => {
    return this.db.collection("productBrands").add({ name: brand });
  };

  doDeleteBrand = (id: string) => {
    return this.db.collection("productBrands").doc(id).delete();
  };

  doRestoreBrand = (b: Brand) =>
    this.db.collection("productBrands").doc(b.id).set(b);
}

export default Firebase;
