import React from "react";
import {
  Container,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from "@material-ui/core";
import styled from "styled-components";
import { ClientModel } from "../../models/Client";
import InputLabel from "@material-ui/core/InputLabel";

type Props = {
  clients: ClientModel[];
  setClient: (string: string) => void;
  client: string;
};

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const SelectClient = (props: Props) => {
  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Wähle einen Lieferanten aus</Typography>
        <FormControl>
          <InputLabel id="lieferant-select-label">Lieferant</InputLabel>
          <Select
            labelId="lieferant-select-label"
            id="lieferant-simple-select"
            value={props.client}
            onChange={(e) => props.setClient(e.target.value as string)}
            style={{ minWidth: 200, maxWidth: "100%" }}
          >
            {props.clients.map((c, i) => (
              <MenuItem value={c.id} key={i}>
                <ListItemText
                  primary={c.lastname + " " + c.firstname}
                  secondary={c.address + ", " + c.city}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Wrapper>
    </React.Fragment>
  );
};

export default SelectClient;
