import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { ClientState } from "./clients/types";
import clientsReducer from "./clients/reducer";
import brandsReducer from "./brands/reducer";
import { BrandState } from "./brands/types";
import categoriesReducer from "./categories/reducer";
import { CategoryState } from "./categories/types";
import productsReducer from "./products/reducer";
import { ProductState } from "./products/types";

export type RootState = {
  clients: ClientState;
  brands: BrandState;
  categories: CategoryState;
  products: ProductState;
};

const rootReducer = combineReducers({
  clients: clientsReducer(),
  brands: brandsReducer(),
  categories: categoriesReducer(),
  products: productsReducer(),
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk)
    // other store enhancers if any
  )
);

export default store;
