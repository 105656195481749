import {ProductModel, ProductStates} from "../../models/Product";

export enum ActionTypes {
  LOADING_PRODUCTS = "LOADING_PRODUCTS",
  FETCHED_PRODUCTS = "FETCHED_PRODUCTS",
  FETCHED_SINGLE_PRODUCT = "FETCHED_SINGLE_PRODUCT",
  FETCHED_PAYOUT_PRODUCTS = "FETCHED_PAYOUT_PRODUCTS",
  FETCHED_LABEL_PRODUCTS = "FETCHED_LABEL_PRODUCTS",
  FETCHED_RETURN_PRODUCTS = "FETCHED_RETURN_PRODUCTS",
  FETCHED_CLIENT_PRODUCTS = "FETCHED_CLIENT_PRODUCTS",
  ERROR_PRODUCTS = "ERROR_PRODUCTS",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  CHANGE_STATE_OF_PRODUCT = "CHANGE_STATE_OF_PRODUCT",
  CHANGE_PRODUCT = "CHANGE_PRODUCT",
}

/**
 * Action Types
 */
export type LoadingProducts = {
  type: ActionTypes.LOADING_PRODUCTS;
  loading: boolean;
};
export type FetchedProducts = {
  products: ProductModel[];
  type: ActionTypes.FETCHED_PRODUCTS;
  loading: boolean;
  initialFetch: boolean;
};
export type FetchedSingleProduct = {
  product: ProductModel;
  type: ActionTypes.FETCHED_SINGLE_PRODUCT;
  loading: boolean;
};
export type FetchedPayOutProducts = {
  products: ProductModel[];
  type: ActionTypes.FETCHED_PAYOUT_PRODUCTS;
  loading: boolean;
  initialFetchPayout: boolean;
};
export type FetchedLabelProducts = {
  products: ProductModel[];
  type: ActionTypes.FETCHED_LABEL_PRODUCTS;
  loading: boolean;
  initialFetchLabel: boolean;
};
export type FetchedReturnProducts = {
  productsToReturn: ProductModel[];
  type: ActionTypes.FETCHED_RETURN_PRODUCTS;
  loading: boolean;
  initialFetchReturn: boolean;
};
export type FetchedClientProducts = {
  products: ProductModel[];
  type: ActionTypes.FETCHED_CLIENT_PRODUCTS;
  loading: boolean;
  initialFetchClient: string;
};
export type ErrorProducts = {
  type: ActionTypes.ERROR_PRODUCTS;
  error: string;
  loading: boolean;
};
export type DeleteProduct = {
  type: ActionTypes.DELETE_PRODUCT;
  id: string;
  loading: boolean;
};
export type ChangeStateOfProduct = {
  type: ActionTypes.CHANGE_STATE_OF_PRODUCT;
  id: string;
  state: ProductStates;
  loading: boolean;
};
export type ChangeProduct = {
  type: ActionTypes.CHANGE_PRODUCT;
  product: ProductModel;
  loading: boolean;
};


/**
 * State Type
 */
export type ProductState = {
  products: ProductModel[];
  productsToReturn: ProductModel[];
  loading: boolean;
  initialFetch: boolean;
  initialFetchLabel: boolean;
  initialFetchPayout: boolean;
  initialFetchReturn: boolean;
  initialFetchClients: string[];
  initialFetchSingle: string[];
  error?: string;
};
