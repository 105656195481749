import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { RouteComponentProps, withRouter } from "react-router";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getAllProductLocations,
  getAllProductStates,
  ProductLocation,
  ProductModel,
  ProductStates,
} from "../models/Product";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import {
  switchCurrentLocation,
  switchCurrentState,
} from "../views/ProductCard";
import Firebase from "./Firebase/Firebase";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";
import { changeProduct, fetchSingleProduct } from "../redux/products/actions";
import { fetchClients } from "../redux/clients/actions";
import { fetchCategories } from "../redux/categories/actions";
import { fetchBrands } from "../redux/brands/actions";
import { MiniImg } from "./MiniImg";
import Compressor from "compressorjs";
import {
  IMAGE_COMPRESSION_MAX_SIZE,
  IMAGE_COMPRESSION_QUALITY,
} from "../constants/image-compression";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  setAppBarTitle: (title: string) => void;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const MyForm = styled.form`
  display: flex;
  flex-direction: column;
  & .MuiTextField-root {
    margin: 10px 0;
    width: 25ch;
  },
`;

const Uploader = styled.div`
  margin-top: 20px;
  & input {
    width: 0;
  }
`;

const PicturePreviewContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
`;

const RemovePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgScroller = styled.div<{ cols: number }>`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(${(props) => props.cols}, auto);
  overflow-x: scroll;
  place-items: start;
  padding: 20px 0;
`;

const EditProduct = (props: Props) => {
  useEffect(() => {
    props.setAppBarTitle("Produkt bearbeiten");
  }, [props]);

  const firebase = Firebase.getInstance();
  const dispatch = useDispatch();
  const id = useMemo(() => props.match.params.id, [props.match.params.id]);

  const {
    products,
    loading: loadingP,
    initialFetchSingle: initP,
  } = useAppState((state) => state.products);
  const { clients, loading: loadingC, initialFetch: initC } = useAppState(
    (state) => state.clients
  );
  const {
    categories,
    loading: loadingCat,
    initialFetch: initCat,
  } = useAppState((state) => state.categories);
  const {
    brands,
    loading: loadingBrands,
    initialFetch: initBrands,
  } = useAppState((state) => state.brands);

  const product = useMemo(() => products.find((p) => p.id === id), [
    products,
    id,
  ]);

  const client = useMemo(
    () => (product ? clients.find((c) => c.id === product.owner) : undefined),
    [product, clients]
  );

  useEffect(() => {
    if (!loadingP && !initP.includes(id)) {
      dispatch(fetchSingleProduct(id));
    }
  }, [loadingP, initP, id, dispatch]);

  useEffect(() => {
    if (!product || !product.owner) return;
    if (!loadingC && !initC) {
      dispatch(fetchClients());
    }
  }, [product, loadingC, initC, dispatch]);

  useEffect(() => {
    if (!loadingCat && !initCat) {
      dispatch(fetchCategories());
    }
  }, [loadingCat, initCat, dispatch]);

  useEffect(() => {
    if (!loadingBrands && !initBrands) {
      dispatch(fetchBrands());
    }
  }, [loadingBrands, initBrands, dispatch]);

  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productExternPrice, setProductExternPrice] = useState(0);
  const [productInternPrice, setProductInternPrice] = useState(0);
  const [productCategory, setProductCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productValidUntil, setProductValidUntil] = useState<Date | null>(
    new Date()
  );
  const [productPictureIds, setProductPictureIds] = useState<string[]>([]);
  const [productSize, setProductSize] = useState("");
  const [productNotes, setProductNotes] = useState("");
  const [productState, setProductState] = useState(ProductStates.STORE);
  const [productLocation, setProductLocation] = useState(ProductLocation.STORE);
  const [isWebsiteHighlight, setIsWebsiteHighlight] = useState(false);
  const [hideOnWebsite, setHideOnWebsite] = useState(false);

  const [pictureUrl, setPictureUrl] = useState<string[]>([]);

  useEffect(() => {
    if (product) {
      if (product.title) setProductTitle(product.title);
      if (product.description) setProductDescription(product.description);
      if (product.externalPrice) setProductExternPrice(product.externalPrice);
      if (product.internalPrice) setProductInternPrice(product.internalPrice);
      if (product.category) setProductCategory(product.category);
      if (product.brand) setProductBrand(product.brand);
      if (product.validUntil) setProductValidUntil(product.validUntil);
      if (product.pictures) setProductPictureIds(product.pictures);
      if (product.urls) setPictureUrl(product.urls);
      if (product.size) setProductSize(product.size);
      if (product.state) setProductState(product.state);
      if (product.location) setProductLocation(product.location);
      if (product.websiteHighlight)
        setIsWebsiteHighlight(product.websiteHighlight);
      if (product.hideOnWebsite) setHideOnWebsite(product.hideOnWebsite);
      if (product.notes) setProductNotes(product.notes);
    }
  }, [product]);

  const [picsToDelete, setPicsToDelete] = useState<string[]>([]);

  let [imagePreview, setImagePreview] = useState<(string | undefined)[]>([]);
  const [productPictures, setProductPictures] = useState<File[]>([]);

  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);

  const [showUploadSuccess, setShowUploadSuccess] = useState(false);
  const [showUploadError, setShowUploadError] = useState(false);
  const [uploadSuccessNumber, setUploadSuccessNumber] = useState("");
  const [uploadErrorNumber, setUploadErrorNumber] = useState("");

  const onSubmit = useCallback(() => {
    if (!product || !product.owner) return;
    let pictureIds: string[] = new Array(productPictures.length)
      .fill("default")
      .map(() => uuidv4());
    productPictures.map((pic, index) =>
      firebase
        ?.doUploadFile(pic, pictureIds[index])
        .then(() => {
          setShowUploadSuccess(true);
          setUploadSuccessNumber((n) => n + ", " + (index + 1));
        })
        .catch(() => {
          setShowUploadError(true);
          setUploadErrorNumber((n) => n + ", " + (index + 1));
        })
    );
    const changedProduct: ProductModel = {
      owner: product.owner,
      title: productTitle,
      description: productDescription,
      internalPrice: productInternPrice,
      externalPrice: productExternPrice,
      validUntil: productValidUntil,
      pictures: [...productPictureIds, ...pictureIds],
      size: productSize,
      category: productCategory,
      brand: productBrand,
      state: productState,
      location: productLocation,
      websiteHighlight: isWebsiteHighlight,
      hideOnWebsite: hideOnWebsite,
      notes: productNotes
    };
    firebase
      ?.doEditProduct(id, changedProduct)
      .then(async () => {
        await Promise.all(
          picsToDelete.map((value) => firebase?.doDeleteFile(value))
        );
        dispatch(changeProduct({ id, ...changedProduct }));
        setShowSuccess(true);
      })
      .catch(() => setShowError(true));
  }, [
    product,
    productPictures,
    dispatch,
    firebase,
    id,
    picsToDelete,
    productBrand,
    productDescription,
    productExternPrice,
    productInternPrice,
    productPictureIds,
    productSize,
    productState,
    productLocation,
    productTitle,
    productValidUntil,
    productCategory,
    isWebsiteHighlight,
    hideOnWebsite,
    productNotes
  ]);

  const addImgPreview = useCallback((newItem: string | undefined) => {
    setImagePreview((p) => [...p, newItem]);
  }, []);

  const removeImgPreviewAtIndex = useCallback(
    (index: number) => {
      setImagePreview(imagePreview.filter((value, index1) => index1 !== index));
      setProductPictures(
        productPictures.filter((value, index1) => index1 !== index)
      );
    },
    [imagePreview, productPictures]
  );

  const removeUploadedImgByUrl = useCallback(
    (url: string, index: number) => {
      if (!product || !product.pictures || product.pictures?.length <= index)
        return;
      const pictureId = product.pictures[index];
      setPictureUrl((p) => [...p.filter((u) => u !== url)]);
      setPicsToDelete((p) => [...p, pictureId]);
      setProductPictureIds((p) => [...p.filter((i) => i !== pictureId)]);
    },
    [product]
  );

  const readNewImage = useCallback(
    (newPic: File) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        addImgPreview(reader.result === null ? "" : reader.result.toString());
      };
      if (newPic) reader.readAsDataURL(newPic);
    },
    [addImgPreview]
  );

  const addPictures = useCallback(
    (files: FileList | null) => {
      if (files === null) return;
      for (let i = 0; i < files.length; i++) {
        const newFile = files.item(i);
        if (newFile !== null) {
          new Compressor(newFile, {
            quality: IMAGE_COMPRESSION_QUALITY,
            maxWidth: IMAGE_COMPRESSION_MAX_SIZE,
            maxHeight: IMAGE_COMPRESSION_MAX_SIZE,
            success(result) {
              readNewImage(result as File);
              setProductPictures((p) => [...p, result as File]);
            },
          });
        }
      }
    },
    [readNewImage]
  );

  if (!product || !client) return null;

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Bearbeite {product.title}</Typography>
        <MyForm noValidate autoComplete="off">
          <FormControl>
            <InputLabel id="category-select-label">
              Produkt-Kategorie
            </InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value as string)}
            >
              {categories.map((c, i) => (
                <MenuItem value={c.name} key={i}>
                  <ListItemText primary={c.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Titel"
            required
            type={"text"}
            value={productTitle}
            onChange={(e) => setProductTitle(e.target.value as string)}
            autoComplete="off"
            name="search"
          />
          <TextField
            label="Beschreibung"
            type={"text"}
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value as string)}
            multiline
            rows={3}
          />
          {productCategory === "Schuhe" && (
            <TextField
              label="Grösse"
              type={"text"}
              value={productSize}
              onChange={(e) => setProductSize(e.target.value as string)}
            />
          )}
          <FormControl>
            <InputLabel id="brands-select-label">Marke</InputLabel>
            <Select
              labelId="brands-select-label"
              id="category-select"
              value={productBrand}
              onChange={(e) => setProductBrand(e.target.value as string)}
            >
              {brands.map((c, i) => (
                <MenuItem value={c.name} key={i}>
                  <ListItemText primary={c.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Kundenpreis"
            required
            type={"number"}
            value={productExternPrice}
            onChange={(e) => {
              setProductExternPrice(Number(e.target.value));
              setProductInternPrice(Number(e.target.value) * 0.4);
            }}
          />
          <TextField
            label="Lieferantenpreis"
            required
            type={"number"}
            value={productInternPrice}
            onChange={(e) => setProductInternPrice(Number(e.target.value))}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="dialog"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-dialog"
              label="Enddatum"
              value={productValidUntil}
              onChange={(date) => setProductValidUntil(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControl>
            <InputLabel id="state-select-label">Status</InputLabel>
            <Select
              labelId="state-select-label"
              id="state-select"
              value={productState}
              onChange={(e) => setProductState(e.target.value as ProductStates)}
            >
              {getAllProductStates().map((c, i) => (
                <MenuItem value={c} key={i}>
                  <ListItemText primary={switchCurrentState(c).toUpperCase()} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="location-select-label">Ort</InputLabel>
            <Select
              labelId="location-select-label"
              id="location-select"
              value={productLocation}
              onChange={(e) =>
                setProductLocation(e.target.value as ProductLocation)
              }
            >
              {getAllProductLocations().map((c, i) => (
                <MenuItem value={c} key={i}>
                  <ListItemText
                    primary={switchCurrentLocation(c).toUpperCase()}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={isWebsiteHighlight}
                onChange={(e) => setIsWebsiteHighlight(e.target.checked)}
                color="primary"
                name="websiteHighlight"
                inputProps={{ "aria-label": "Website Highlight" }}
              />
            }
            label={"Website Highlight"}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideOnWebsite}
                onChange={(e) => setHideOnWebsite(e.target.checked)}
                color="primary"
                name="websiteHiding"
                inputProps={{ "aria-label": "Website Hiding" }}
              />
            }
            label={"Auf der Website verbergen"}
          />
          <TextField
            label="Notizen"
            type={"text"}
            value={productNotes}
            onChange={(e) => setProductNotes(e.target.value as string)}
            multiline
            rows={3}
          />
          <Uploader>
            <input
              id={"fileInput"}
              type="file"
              onChange={(e) => addPictures(e.target.files)}
              accept="image/*"
              multiple
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                document.getElementById("fileInput")?.click();
              }}
            >
              Bilder hinzufügen
            </Button>
          </Uploader>
          <ImgScroller cols={imagePreview.length}>
            {pictureUrl.map((value, index) => (
              <PicturePreviewContainer>
                <MiniImg key={index} src={value} alt={value} />
                <RemovePreviewContainer
                  onClick={() => removeUploadedImgByUrl(value, index)}
                >
                  <DeleteIcon />
                </RemovePreviewContainer>
              </PicturePreviewContainer>
            ))}
            {imagePreview.map((src, index) => (
              <PicturePreviewContainer>
                {src && <MiniImg src={src} alt={productPictures[index].name} />}
                <RemovePreviewContainer
                  onClick={() => removeImgPreviewAtIndex(index)}
                >
                  <DeleteIcon />
                </RemovePreviewContainer>
              </PicturePreviewContainer>
            ))}
          </ImgScroller>
          <Button
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={() => onSubmit()}
          >
            Bearbeiten
          </Button>
        </MyForm>
      </Wrapper>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Produkt erfolgreich bearbeitet
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Produkt konnte nicht bearbeitet werden
        </Alert>
      </Snackbar>
      <Snackbar
        open={showUploadSuccess}
        autoHideDuration={6000}
        onClose={() => setShowUploadSuccess(false)}
        style={{ marginBottom: 60 }}
      >
        <Alert onClose={() => setShowUploadSuccess(false)} severity="success">
          Bild(er) {uploadSuccessNumber}/{productPictures.length} wurde(n)
          hochgeladen
        </Alert>
      </Snackbar>
      <Snackbar
        open={showUploadError}
        autoHideDuration={6000}
        onClose={() => setShowUploadError(false)}
        style={{ marginBottom: 120 }}
      >
        <Alert onClose={() => setShowUploadError(false)} severity="success">
          Bild(er) {uploadErrorNumber} konnte(n) nicht hochgeladen werden
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default withRouter(EditProduct);
