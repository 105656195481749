import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Login";
import * as ROUTES from "../constants/routes";
import LoggedInGuard from "./LoggedInGuard";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import Firebase from "./Firebase/Firebase";

const ScreenLoader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false);

  let firebase = Firebase.getInstance();

  useEffect(() => {
    firebase?.isInitialized().then(() => {
      setFirebaseInitialized(true);
    });
  });

  return firebaseInitialized ? (
    <Router>
      <Route path={ROUTES.LOGGEDIN} component={LoggedInGuard} />
      <Route path={ROUTES.LOGIN} component={Login} />
      <Route exact path={ROUTES.LANDING} component={Login} />
    </Router>
  ) : (
    <ScreenLoader>
      <CircularProgress />
    </ScreenLoader>
  );
}

export default App;
