import {
  ActionTypes,
  ChangeStateOfProduct,
  DeleteProduct,
  ErrorProducts,
  FetchedClientProducts,
  FetchedLabelProducts,
  FetchedPayOutProducts,
  FetchedProducts,
  FetchedReturnProducts,
  FetchedSingleProduct,
  LoadingProducts,
  ProductState,
  ChangeProduct,
} from "./types";
import { ProductStates } from "../../models/Product";

const initialState: ProductState = {
  products: [],
  productsToReturn: [],
  initialFetch: false,
  initialFetchPayout: false,
  initialFetchLabel: false,
  initialFetchReturn: false,
  initialFetchClients: [],
  initialFetchSingle: [],
  loading: false,
};

const productsReducer = () => {
  return (
    state = initialState,
    action:
      | FetchedProducts
      | FetchedSingleProduct
      | FetchedPayOutProducts
      | FetchedLabelProducts
      | FetchedReturnProducts
      | FetchedClientProducts
      | ErrorProducts
      | LoadingProducts
      | DeleteProduct
      | ChangeStateOfProduct
      | ChangeProduct
  ) => {
    console.log("reducing products", action);
    switch (action.type) {
      case ActionTypes.LOADING_PRODUCTS:
        return { ...state, loading: action.loading };
      case ActionTypes.FETCHED_PRODUCTS:
        return {
          ...state,
          products: action.products,
          initialFetch: action.initialFetch,
          initialFetchSingle: action.products.map((c) => c.id),
          loading: action.loading,
        };
      case ActionTypes.FETCHED_SINGLE_PRODUCT:
        return {
          ...state,
          products: [
            ...state.products.filter((p) => p.id !== action.product.id),
            action.product,
          ],
          initialFetchSingle: [
            ...state.initialFetchSingle.filter((p) => p !== action.product.id),
            action.product.id,
          ],
          loading: action.loading,
        };
      case ActionTypes.FETCHED_PAYOUT_PRODUCTS:
        return {
          ...state,
          products: [
            ...state.products.filter((p) => p.state !== ProductStates.SOLD),
            ...action.products,
          ],
          initialFetchPayout: action.initialFetchPayout,
          initialFetchSingle: [
            ...state.initialFetchSingle,
            ...action.products
              .filter((p) => !state.initialFetchSingle.includes(String(p.id)))
              .map((p) => p.id),
          ],
          loading: false,
        };
      case ActionTypes.FETCHED_LABEL_PRODUCTS:
        return {
          ...state,
          products: [
            ...state.products.filter(
              (p) => p.state !== ProductStates.REGISTERED
            ),
            ...action.products,
          ],
          initialFetchLabel: action.initialFetchLabel,
          initialFetchSingle: [
            ...state.initialFetchSingle,
            ...action.products
              .filter((p) => !state.initialFetchSingle.includes(String(p.id)))
              .map((p) => p.id),
          ],
          loading: false,
        };
      case ActionTypes.FETCHED_RETURN_PRODUCTS:
        return {
          ...state,
          productsToReturn: action.productsToReturn,
          initialFetchReturn: action.initialFetchReturn,
          loading: false,
        };
      case ActionTypes.FETCHED_CLIENT_PRODUCTS:
        return {
          ...state,
          products: [
            ...state.products.filter(
              (p) => p.owner !== action.initialFetchClient
            ),
            ...action.products,
          ],
          initialFetchClients: [
            ...state.initialFetchClients.filter(
              (c) => c !== action.initialFetchClient
            ),
            action.initialFetchClient,
          ],
          initialFetchSingle: [
            ...state.initialFetchSingle,
            ...action.products
              .filter((p) => !state.initialFetchSingle.includes(String(p.id)))
              .map((p) => p.id),
          ],
          loading: false,
        };
      case ActionTypes.ERROR_PRODUCTS:
        return { ...state, error: action.error, loading: action.loading };
      case ActionTypes.DELETE_PRODUCT:
        return {
          ...state,
          products: [...state.products.filter((p) => p.id !== action.id)],
          productsToReturn: [
            ...state.productsToReturn.filter((p) => p.id !== action.id),
          ],
          loading: action.loading,
        };
      case ActionTypes.CHANGE_STATE_OF_PRODUCT:
        return {
          ...state,
          products: [
            ...state.products.map((p) => {
              if (p.id !== action.id) return p;
              else return { ...p, state: action.state };
            }),
          ],
          productsToReturn: [
            ...state.productsToReturn.map((p) => {
              if (p.id !== action.id) return p;
              else return { ...p, state: action.state };
            }),
          ],
          loading: action.loading,
        };
      case ActionTypes.CHANGE_PRODUCT:
        return {
          ...state,
          products: [
            ...state.products.map((p) => {
              if (p.id !== action.product.id) return p;
              else return action.product;
            }),
          ],
          productsToReturn: [
            ...state.productsToReturn.map((p) => {
              if (p.id !== action.product.id) return p;
              else return action.product;
            }),
          ],
          loading: action.loading,
        };
      default:
        return state;
    }
  };
};

export default productsReducer;
