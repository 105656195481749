import {
  ActionTypes,
  AddBrand,
  BrandState,
  DeleteBrand,
  ErrorBrands,
  FetchedBrands,
  LoadingBrands,
} from "./types";
import { compareStrings } from "../../helper/string-compare";

const initialState: BrandState = {
  brands: [],
  initialFetch: false,
  loading: false,
};

const brandsReducer = () => {
  return (
    state = initialState,
    action: FetchedBrands | ErrorBrands | LoadingBrands | DeleteBrand | AddBrand
  ) => {
    switch (action.type) {
      case ActionTypes.LOADING_BRANDS:
        return { ...state, loading: action.loading };
      case ActionTypes.FETCHED_BRANDS:
        return {
          ...state,
          brands: action.brands,
          initialFetch: action.initialFetch,
          loading: action.loading,
        };
      case ActionTypes.ERROR_BRANDS:
        return { ...state, error: action.error, loading: action.loading };
      case ActionTypes.DELETE_BRAND:
        return {
          ...state,
          brands: state.brands.filter((b) => b.id !== action.id),
          loading: action.loading,
        };
      case ActionTypes.ADD_BRAND:
        return {
          ...state,
          brands: [...state.brands, action.brand].sort((a, b) =>
            compareStrings(a.name ? a.name : "", b.name ? b.name : "")
          ),
        };
      default:
        return state;
    }
  };
};

export default brandsReducer;
