import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";

const IMG_SIZE = 150;
const MODAL_SPACING = 80;

const MyImg = styled.img`
  width: ${IMG_SIZE}px;
  height: ${IMG_SIZE}px;
  object-fit: contain;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;

const MyBigImg = styled.img`
  width: calc(100% - ${MODAL_SPACING}px);
  height: calc(100% - ${MODAL_SPACING}px);
  object-fit: contain;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  background-color: white;
  margin-left: ${MODAL_SPACING / 2}px;
  margin-top: ${MODAL_SPACING / 2}px;
`;

type Props = {
  src: string;
  alt: string;
};

export const MiniImg: React.FC<Props> = ({ src, alt }) => {
  const [isImgBig, setIsImgBig] = useState(false);

  const handleOpen = useCallback(() => setIsImgBig(true), []);
  const handleClose = useCallback(() => setIsImgBig(false), []);

  return (
    <>
      <MyImg src={src} alt={alt} onClick={handleOpen} />
      {isImgBig && (
        <Modal
          open={isImgBig}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <MyBigImg src={src} alt={alt} onClick={handleClose} />
        </Modal>
      )}
    </>
  );
};
