import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import useAppState from "../../redux/useAppState";
import { useDispatch } from "react-redux";
import { fetchBrands } from "../../redux/brands/actions";
import { fetchCategories } from "../../redux/categories/actions";
import { MiniImg } from "../MiniImg";
import Compressor from "compressorjs";
import {
  IMAGE_COMPRESSION_MAX_SIZE,
  IMAGE_COMPRESSION_QUALITY,
} from "../../constants/image-compression";

type Props = {
  title: string;
  setTitle: (input: string) => void;
  description: string;
  setDescription: (input: string) => void;
  externPrice: number;
  setExternPrice: (input: number) => void;
  internPrice: number;
  setInternPrice: (input: number) => void;
  pictures: File[];
  setPictures: React.Dispatch<React.SetStateAction<File[]>>;
  validUntil: Date | null;
  setValidUntil: (date: Date | null) => void;
  category: string;
  setCategory: (input: string) => void;
  size: string;
  setSize: (input: string) => void;
  brand: string;
  setBrand: (input: string) => void;
  isWebsiteHighlight: boolean;
  setIsWebsiteHighlight: (input: boolean) => void;
  hideOnWebsite: boolean;
  setHideOnWebsite: (input: boolean) => void;
  notes: string;
  setNotes: (input: string) => void;
};

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const MyForm = styled.form`
  display: flex;
  flex-direction: column;
  & .MuiTextField-root {
    margin: 10px 0;
    width: 25ch;
  },
`;

const Uploader = styled.div`
  margin-top: 20px;
  & input {
    width: 0;
  }
`;

const PicturePreviewContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
`;

const RemovePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgScroller = styled.div<{ cols: number }>`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(${(props) => props.cols}, auto);
  overflow-x: scroll;
  place-items: start;
  padding: 20px 0;
`;

const CreateProduct: React.FC<Props> = ({
  title,
  setTitle,
  description,
  setDescription,
  externPrice,
  setExternPrice,
  internPrice,
  setInternPrice,
  pictures,
  setPictures,
  validUntil,
  setValidUntil,
  category,
  setCategory,
  size,
  setSize,
  brand,
  setBrand,
  isWebsiteHighlight,
  setIsWebsiteHighlight,
  hideOnWebsite,
  setHideOnWebsite,
  notes,
  setNotes
}) => {
  const dispatch = useDispatch();
  let [imagePreview, setImagePreview] = useState<(string | undefined)[]>([]);
  const { categories, initialFetch: initialFetchCategories } = useAppState(
    (state) => state.categories
  );
  const { brands, initialFetch: initialFetchBrands } = useAppState(
    (state) => state.brands
  );

  const addImgPreview = useCallback((newItem: string | undefined) => {
    setImagePreview((p) => [...p, newItem]);
  }, []);

  const removeImgPreviewAtIndex = useCallback(
    (index: number) => {
      setImagePreview((i) => i.filter((value, index1) => index1 !== index));
      setPictures((p) => p.filter((value, index1) => index1 !== index));
    },
    [setPictures]
  );

  const readNewImage = useCallback(
    (newPic: File) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        addImgPreview(reader.result === null ? "" : reader.result.toString());
      };
      if (newPic) reader.readAsDataURL(newPic);
    },
    [addImgPreview]
  );

  const addPictures = useCallback(
    (files: FileList | null) => {
      if (files === null) return;
      for (let i = 0; i < files.length; i++) {
        const newFile = files.item(i);
        if (newFile !== null) {
          new Compressor(newFile, {
            quality: IMAGE_COMPRESSION_QUALITY,
            maxWidth: IMAGE_COMPRESSION_MAX_SIZE,
            maxHeight: IMAGE_COMPRESSION_MAX_SIZE,
            success(result) {
              readNewImage(result as File);
              setPictures((p) => [...p, result as File]);
            },
          });
        }
      }
    },
    [setPictures, readNewImage]
  );

  useEffect(() => {
    if (!initialFetchCategories) dispatch(fetchCategories());
    if (!initialFetchBrands) dispatch(fetchBrands());
  }, [dispatch, initialFetchCategories, initialFetchBrands]);

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6">Erfasse Produkt-Daten</Typography>
        <MyForm noValidate autoComplete="off">
          <FormControl>
            <InputLabel id="category-select-label">
              Produkt-Kategorie
            </InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={category}
              onChange={(e) => setCategory(e.target.value as string)}
            >
              {categories.map((c, i) => (
                <MenuItem value={c.name} key={i}>
                  <ListItemText primary={c.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Titel"
            required
            type={"text"}
            value={title}
            onChange={(e) => setTitle(e.target.value as string)}
            autoComplete="off"
            name="search"
          />
          <TextField
            label="Beschreibung"
            type={"text"}
            value={description}
            onChange={(e) => setDescription(e.target.value as string)}
            multiline
            rows={3}
          />
          {category === "Schuhe" && (
            <TextField
              label="Grösse"
              type={"text"}
              value={size}
              onChange={(e) => setSize(e.target.value as string)}
            />
          )}
          <FormControl>
            <InputLabel id="brands-select-label">Marke</InputLabel>
            <Select
              labelId="brands-select-label"
              id="category-select"
              value={brand}
              onChange={(e) => setBrand(e.target.value as string)}
            >
              {brands.map((c, i) => (
                <MenuItem value={c.name} key={i}>
                  <ListItemText primary={c.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Kundenpreis"
            required
            type={"number"}
            value={externPrice}
            onChange={(e) => {
              setExternPrice(Number(e.target.value));
              setInternPrice(Number(e.target.value) * 0.4);
            }}
          />
          <TextField
            label="Lieferantenpreis"
            required
            type={"number"}
            value={internPrice}
            onChange={(e) => setInternPrice(Number(e.target.value))}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              required
              disableToolbar
              variant="dialog"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-dialog"
              label="Enddatum"
              value={validUntil}
              onChange={(date) => setValidUntil(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <FormControlLabel
            control={
              <Switch
                checked={isWebsiteHighlight}
                onChange={(e) => setIsWebsiteHighlight(e.target.checked)}
                color="primary"
                name="websiteHighlight"
                inputProps={{ "aria-label": "Website Highlight" }}
              />
            }
            label={"Website Highlight"}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hideOnWebsite}
                onChange={(e) => setHideOnWebsite(e.target.checked)}
                color="primary"
                name="websiteHiding"
                inputProps={{ "aria-label": "Website Hiding" }}
              />
            }
            label={"Auf der Website verbergen"}
          />
          <TextField
            label="Notizen"
            type={"text"}
            value={notes}
            onChange={(e) => setNotes(e.target.value as string)}
            multiline
            rows={3}
          />
          <Uploader>
            <input
              id={"fileInput"}
              type="file"
              onChange={(e) => addPictures(e.target.files)}
              accept="image/*"
              multiple
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                document.getElementById("fileInput")?.click();
              }}
            >
              Bilder hinzufügen
            </Button>
          </Uploader>
          <ImgScroller cols={imagePreview.length}>
            {imagePreview.map((src, index) => (
              <PicturePreviewContainer>
                {src && <MiniImg src={src} alt={pictures[index].name} />}
                <RemovePreviewContainer
                  onClick={() => removeImgPreviewAtIndex(index)}
                >
                  <DeleteIcon />
                </RemovePreviewContainer>
              </PicturePreviewContainer>
            ))}
          </ImgScroller>
        </MyForm>
      </Wrapper>
    </React.Fragment>
  );
};

export default CreateProduct;
