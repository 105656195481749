import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { CLIENTS, PRODUCTS } from "../constants/routes";
import ConfirmationDialog from "./ConfirmationDialog";
import { format } from "date-fns";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import UnstyledLink from "./UnstyledLink";
import {
  switchCurrentLocation,
  switchCurrentState,
} from "../views/ProductCard";
import { undefinedRemoverFromString } from "../helper/undefinedRemover";
import Firebase from "./Firebase/Firebase";
import useAppState from "../redux/useAppState";
import { useDispatch } from "react-redux";
import { fetchSingleProduct, deleteProduct } from "../redux/products/actions";
import { fetchSpecificClient } from "../redux/clients/actions";
import { MiniImg } from "./MiniImg";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
`;

const ImageSlider = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fill, auto);
  grid-column-gap: 10px;
  overflow-x: scroll;
  padding: 20px 0;
  height: 300px;
`;

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  setAppBarTitle: (title: string) => void;
}

const Product = (props: Props) => {
  const firebase = Firebase.getInstance();
  const dispatch = useDispatch();
  const id = useMemo(() => props.match.params.id, [props.match.params.id]);

  const {
    products,
    loading: loadingP,
    initialFetchSingle: initP,
  } = useAppState((state) => state.products);
  const { clients, loading: loadingC, initialFetchSingle: initC } = useAppState(
    (state) => state.clients
  );

  const product = useMemo(() => products.find((p) => p.id === id), [
    products,
    id,
  ]);

  const client = useMemo(
    () => (product ? clients.find((c) => c.id === product.owner) : undefined),
    [product, clients]
  );

  let [showSuccess, setShowSuccess] = useState(false);
  let [showError, setShowError] = useState(false);

  let [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    props.setAppBarTitle("Produkt");
  }, [props]);

  useEffect(() => {
    if (!loadingP && !initP.includes(id)) dispatch(fetchSingleProduct(id));
  }, [loadingP, initP, id, dispatch]);

  useEffect(() => {
    if (!product || !product.owner) return;
    if (!loadingC && !initC.includes(product.owner))
      dispatch(fetchSpecificClient(product.owner));
  });

  const onDeleteProduct = useCallback(() => {
    firebase
      .doDeleteProduct(id)
      .then(() => {
        setShowSuccess(true);
        dispatch(deleteProduct(id));
        props.history.push(PRODUCTS);
      })
      .catch(() => setShowError(true));
    product?.pictures?.map((value) => firebase.doDeleteFile(value));
  }, [firebase, product, id, props.history, dispatch]);

  const handleConfirmation = (confirmation: boolean) => {
    setShowDeleteConfirmation(false);
    if (confirmation) onDeleteProduct();
  };

  if (!product || !client) return null;

  return (
    <React.Fragment>
      <Wrapper>
        <Typography variant="h6" style={{ marginBottom: 20 }}>
          {product.title}
        </Typography>
        <Typography variant="body2" style={{ marginBottom: 20 }}>
          {product.description}
        </Typography>
        <TableContainer style={{ marginBottom: 20 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Produkt Kategorie</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{product?.category}</Typography>
                </TableCell>
              </TableRow>
              {product.category === "Schuhe" && (
                <TableRow>
                  <TableCell>
                    <Typography variant="caption">Grösse</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{product?.size}</Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Kundenpreis</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    CHF {product.externalPrice?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Lieferantenpreis</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    CHF {product.internalPrice?.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Gültig bis</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.validUntil !== undefined &&
                    product.validUntil !== null
                      ? format(product.validUntil, "dd/MM/yyyy")
                      : "unbekannt"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {switchCurrentState(
                      undefinedRemoverFromString(product.state)
                    ).toUpperCase()}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Ort</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {switchCurrentLocation(
                      undefinedRemoverFromString(product.location)
                    ).toUpperCase()}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Webseiten Highlight</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.websiteHighlight ? "Ja" : "Nein"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">
                    Auf der Webseite verbergen
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {product.hideOnWebsite ? "Ja" : "Nein"}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Artikelnummer</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{product.id}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="caption">Notizen</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{product.notes}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <List style={{ marginBottom: 20 }}>
          <ListItem>
            <ListItemText
              primary={client.lastname + " " + client.firstname}
              secondary={client.address + ", " + client.city}
            />
            <ListItemSecondaryAction>
              <UnstyledLink to={CLIENTS + "/" + client.id}>
                <IconButton edge="end" aria-label="delete">
                  <KeyboardArrowRightIcon />
                </IconButton>
              </UnstyledLink>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <ImageSlider>
          {product.urls?.map((value, index) => (
            <MiniImg key={index} src={value} alt={value} />
          ))}
        </ImageSlider>
        <div style={{ visibility: "visible" }}>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() =>
              props.history.push(`${PRODUCTS}/${props.match.params.id}/edit`)
            }
          >
            Bearbeiten
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            style={{ marginLeft: 10 }}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Löschen
          </Button>
        </div>
      </Wrapper>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          Lieferant erfolgreich gelöscht
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          Produkt konnte nicht gelöscht werden
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        open={showDeleteConfirmation}
        title={"Löschen bestätigen"}
        onClose={(confirmation: boolean) => handleConfirmation(confirmation)}
        confirmText={"Löschen"}
      >
        Bist du dir sicher, dass du diesen Eintrag löschen möchtest?
      </ConfirmationDialog>
    </React.Fragment>
  );
};
export default withRouter(Product);
