import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import SelectClient from "./SelectClient";
import CreateProduct from "./CreateProduct";
import ConfirmNewProduct from "./ConfirmNewProduct";
import { v4 as uuidv4 } from "uuid";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { ProductLocation, ProductStates } from "../../models/Product";
import Firebase from "../Firebase/Firebase";
import { useDispatch } from "react-redux";
import { fetchSingleProduct } from "../../redux/products/actions";
import useAppState from "../../redux/useAppState";
import { fetchClients } from "../../redux/clients/actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const addMonths = (date: Date, months: number) =>
  new Date(date.setMonth(date.getMonth() + months));

const NewProductProcess = (props: Props) => {
  let firebase = Firebase.getInstance();
  const dispatch = useDispatch();

  // const [clients, setClients] = useState<ClientModel[]>([]);
  const { clients, initialFetch: initialFetchClients } = useAppState(
    (state) => state.clients
  );
  const [activeStep, setActiveStep] = useState(0);
  const [productOwner, setProductOwner] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productExternPrice, setProductExternPrice] = useState(0);
  const [productInternPrice, setProductInternPrice] = useState(0);
  const [productCategory, setProductCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productValidUntil, setProductValidUntil] = useState<Date | null>(
    addMonths(new Date(), 6)
  );
  const [isWebsiteHighlight, setIsWebsiteHightlight] = useState(false);
  const [hideOnWebsite, setHideOnWebsite] = useState(false);
  const [productPictures, setProductPictures] = useState<File[]>([]);
  const [productSize, setProductSize] = useState("");
  const [productNotes, setProductNotes] = useState("");

  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const stepCompleted = (step: number) => {
    switch (step) {
      case 0:
        return !(productOwner !== "");
      case 1:
        return !(
          productTitle !== "" &&
          productExternPrice !== 0 &&
          productInternPrice !== 0 &&
          productValidUntil !== null
        );
      case 2:
        return false;
    }
  };

  useEffect(() => {
    props.setAppBarTitle("Neues Produkt");
  }, [props]);

  const submit = useCallback(() => {
    let pictureIds: string[] = new Array(productPictures.length)
      .fill("default")
      .map(() => uuidv4());
    setIsUploading(true);
    firebase
      .doCreateProduct({
        owner: productOwner,
        title: productTitle,
        description: productDescription,
        internalPrice: productInternPrice,
        externalPrice: productExternPrice,
        validUntil: productValidUntil,
        pictures: pictureIds,
        size: productSize,
        category: productCategory,
        brand: productBrand,
        state: ProductStates.REGISTERED,
        location: ProductLocation.STORE,
        websiteHighlight: isWebsiteHighlight,
        hideOnWebsite: hideOnWebsite,
        notes: productNotes,
      })
      .then((doc) => {
        Promise.all(
          productPictures.map((pic, index) =>
            firebase.doUploadFile(pic, pictureIds[index])
          )
        )
          .then(() => {
            setIsUploading(false);
            setIsUploadSuccess(true);
            dispatch(fetchSingleProduct(doc.id));
          })
          .catch(() => {
            firebase.doDeleteProduct(doc.id);
            setIsUploading(false);
            setIsUploadError(true);
          });
      })
      .catch(() => setIsUploadSuccess(true));
  }, [
    dispatch,
    firebase,
    isWebsiteHighlight,
    productBrand,
    productCategory,
    productDescription,
    productExternPrice,
    productInternPrice,
    productOwner,
    productPictures,
    productSize,
    productTitle,
    productValidUntil,
    hideOnWebsite,
    productNotes,
  ]);

  useEffect(() => {
    if (!initialFetchClients) {
      dispatch(fetchClients());
    }
  }, [initialFetchClients, dispatch]);

  return (
    <React.Fragment>
      <Wrapper>
        <Stepper
          activeStep={activeStep}
          orientation={
            useMediaQuery("(min-width:600px)") ? "horizontal" : "vertical"
          }
        >
          <Step completed={productOwner !== ""}>
            <StepLabel>Lieferant auswählen</StepLabel>
          </Step>
          <Step>
            <StepLabel>Produkt erfassen</StepLabel>
          </Step>
          <Step>
            <StepLabel>Bestätigen</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <SelectClient
            clients={clients}
            setClient={(id: string) => setProductOwner(id)}
            client={productOwner}
          />
        )}
        {activeStep === 1 && (
          <CreateProduct
            title={productTitle}
            setTitle={setProductTitle}
            description={productDescription}
            setDescription={setProductDescription}
            externPrice={productExternPrice}
            setExternPrice={setProductExternPrice}
            internPrice={productInternPrice}
            setInternPrice={setProductInternPrice}
            pictures={productPictures}
            setPictures={setProductPictures}
            validUntil={productValidUntil}
            setValidUntil={setProductValidUntil}
            category={productCategory}
            setCategory={setProductCategory}
            size={productSize}
            setSize={setProductSize}
            brand={productBrand}
            setBrand={setProductBrand}
            isWebsiteHighlight={isWebsiteHighlight}
            setIsWebsiteHighlight={setIsWebsiteHightlight}
            hideOnWebsite={hideOnWebsite}
            setHideOnWebsite={setHideOnWebsite}
            notes={productNotes}
            setNotes={setProductNotes}
          />
        )}
        {activeStep === 2 && (
          <ConfirmNewProduct
            clients={clients}
            client={productOwner}
            product={{
              title: productTitle,
              description: productDescription,
              externalPrice: productExternPrice,
              internalPrice: productInternPrice,
              validUntil: productValidUntil,
              size: productSize,
              category: productCategory,
              brand: productBrand,
            }}
          />
        )}

        <div>
          <Button
            disabled={activeStep === 0}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Zurück
          </Button>
          <Button
            disabled={stepCompleted(activeStep)}
            variant="contained"
            color="primary"
            onClick={() => {
              activeStep === 2 ? submit() : setActiveStep(activeStep + 1);
            }}
          >
            {activeStep === 2 ? "Erfassen" : "Weiter"}
          </Button>
        </div>
      </Wrapper>
      <Snackbar
        open={isUploading || isUploadSuccess || isUploadError}
        autoHideDuration={isUploadSuccess || isUploadError ? 6000 : null}
        onClose={() => {
          setIsUploadSuccess(false);
          setIsUploadError(false);
        }}
      >
        <Alert
          severity={
            isUploading
              ? "info"
              : isUploadSuccess
              ? "success"
              : isUploadError
              ? "error"
              : "info"
          }
        >
          {isUploading
            ? "wird hochgeladen"
            : isUploadSuccess
            ? "Upload erfolgreich"
            : isUploadError
            ? "Upload fehlgeschlagen"
            : ""}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default NewProductProcess;
