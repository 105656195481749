enum environment {
  DEV,
  PROD,
}

const currentEnv = environment.PROD;

const prodConfig = {
  apiKey: "AIzaSyBn57sH-32dmACs2lwmMeUyTC4FP_zzZDA",
  authDomain: "schuhstall-intern.firebaseapp.com",
  databaseURL: "https://schuhstall-intern.firebaseio.com",
  projectId: "schuhstall-intern",
  storageBucket: "schuhstall-intern.appspot.com",
  messagingSenderId: "975226456741",
  appId: "1:975226456741:web:137e2a08bc883044d85b4c",
  measurementId: "G-PH7TZ5KCE8",
};

const devConfig = {
  apiKey: "AIzaSyAVRhnBVMigcTj4txOwrEGlcopi_rUQOeQ",
  authDomain: "schuhstall-intern-beta.firebaseapp.com",
  databaseURL:
    "https://schuhstall-intern-beta-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "schuhstall-intern-beta",
  storageBucket: "schuhstall-intern-beta.appspot.com",
  messagingSenderId: "35295195440",
  appId: "1:35295195440:web:9ce475a00fd24dc52e18f5",
};

// @ts-ignore
export const firebaseConfig = currentEnv === environment.PROD ? prodConfig : devConfig;
