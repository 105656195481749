import styled from "styled-components";
import { Button, Container, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Firebase from "./Firebase/Firebase";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import { ProductModel } from "../models/Product";
import { ClientModel } from "../models/Client";
import { Category } from "../models/Category";
import { Brand } from "../models/Brand";

const Wrapper = styled(Container)`
  margin: 40px 0;
  width: 100%;
  max-width: 600px;
`;

const Spacer = styled.div`
  height: 20px;
`;

const Uploader = styled.div`
  & input {
    width: 0;
  }
`;

const FilePreviewContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

type Props = {
  setAppBarTitle: (title: string) => void;
};

const t = (n: number) =>
  n.toLocaleString("de-CH", {
    minimumIntegerDigits: 2,
  });
const Backup: React.FC<Props> = ({ setAppBarTitle }) => {
  useEffect(() => {
    setAppBarTitle("Backup");
  }, [setAppBarTitle]);

  const firebase = Firebase.getInstance();

  const [uploadedFileObject, setUploadedFileObject] = useState<any>(null);

  const [productsDownloadErr, setProductsDownloadErr] = useState("");
  const [clientsDownloadErr, setClientsDownloadErr] = useState("");
  const [brandsDownloadErr, setBrandsDownloadErr] = useState("");
  const [categoriesDownloadErr, setCategoriesDownloadErr] = useState("");
  const [uploadFileErr, setUploadFileErr] = useState("");

  const downloadObject = useCallback((filename: string, obj: any) => {
    const dataStr =
      "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    const currentTime = new Date();
    const timestamp = `${currentTime.getFullYear()}${t(
      currentTime.getMonth()
    )}${t(currentTime.getDate())}-${t(currentTime.getHours())}${t(
      currentTime.getMinutes()
    )}`;
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute(
      "download",
      `${filename}_${timestamp}.json`
    );
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }, []);

  const handleDownloadProducts = useCallback(async () => {
    try {
      const result = await firebase.doGetAllProducts();
      const products = result.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("downloading", products.length, "products");
      downloadObject("products", products);
    } catch (e) {
      console.log(e);
      setProductsDownloadErr(e.toString());
    }
  }, [firebase, downloadObject]);

  const handleDownloadClients = useCallback(async () => {
    try {
      const result = await firebase.doGetAllClients();
      const clients = result.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      downloadObject("clients", clients);
    } catch (e) {
      console.log(e);
      setClientsDownloadErr(e.toString());
    }
  }, [firebase, downloadObject]);

  const handleDownloadBrands = useCallback(async () => {
    try {
      const result = await firebase.doGetAllBrands();
      const brands = result.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      downloadObject("brands", brands);
    } catch (e) {
      console.log(e);
      setBrandsDownloadErr(e.toString());
    }
  }, [firebase, downloadObject]);

  const handleDownloadCategories = useCallback(async () => {
    try {
      const result = await firebase.doGetAllCategories();
      const categories = result.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      downloadObject("categories", categories);
    } catch (e) {
      console.log(e);
      setCategoriesDownloadErr(e.toString());
    }
  }, [firebase, downloadObject]);

  const handleUploadFile = useCallback((files: FileList | null) => {
    if (files === null) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        const uploadedObj = JSON.parse(reader.result.toString());
        console.log(uploadedObj);
        setUploadedFileObject(uploadedObj);
        document.getElementById("JSON-preview")!.textContent = JSON.stringify(
          uploadedObj,
          undefined,
          2
        );
      }
    };
    if (files.length > 0) reader.readAsText(files[0]);
  }, []);

  const handleUploadProducts = useCallback(async () => {
    try {
      const products = uploadedFileObject as ProductModel[];
      const didUploadAtIndex = await Promise.all(
        products.map(async (p) => {
          try {
            if (!p.id) throw new Error("no product id");
            const res = await firebase.doGetSpecificProduct(p.id);
            if (res.data()) throw new Error("product already exists");
            await firebase.doRestoreProduct(p.id, p);
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        })
      );
      downloadObject(
        "not_restored",
        products.filter((_, i) => !didUploadAtIndex[i])
      );
    } catch (e) {
      console.log(e);
      setUploadFileErr(e.toString());
    }
  }, [uploadedFileObject, firebase, downloadObject]);

  const handleUploadClients = useCallback(async () => {
    try {
      const clients = uploadedFileObject as ClientModel[];
      const didUploadAtIndex = await Promise.all(
        clients.map(async (p) => {
          try {
            if (!p.id) throw new Error("no client id");
            const res = await firebase.doGetSpecificClient(p.id);
            if (res.data()) throw new Error("product already exists");
            await firebase.doRestoreClient(p);
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        })
      );
      downloadObject(
        "not_restored",
        clients.filter((_, i) => !didUploadAtIndex[i])
      );
    } catch (e) {
      console.log(e);
      setUploadFileErr(e.toString());
    }
  }, [uploadedFileObject, firebase, downloadObject]);

  const handleUploadCategories = useCallback(async () => {
    try {
      const categories = uploadedFileObject as Category[];
      const existingCategoriesRes = await firebase.doGetAllCategories();
      const existingCategories = existingCategoriesRes.docs.map(
        (doc) => doc.data().name
      );
      const didUploadAtIndex = await Promise.all(
        categories.map(async (c) => {
          try {
            if (!c.id) throw new Error("no category id");
            const res = existingCategories.some((n) => n === c.name);
            if (res) throw new Error("category already exists");
            await firebase.doRestoreCategory(c);
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        })
      );
      downloadObject(
        "not_restored",
        categories.filter((_, i) => !didUploadAtIndex[i])
      );
    } catch (e) {
      console.log(e);
      setUploadFileErr(e.toString());
    }
  }, [uploadedFileObject, firebase, downloadObject]);

  const handleUploadBrands = useCallback(async () => {
    try {
      const brands = uploadedFileObject as Brand[];
      const existingBrandsRes = await firebase.doGetAllBrands();
      const existingBrands = existingBrandsRes.docs.map(
        (doc) => doc.data().name
      );
      const didUploadAtIndex = await Promise.all(
        brands.map(async (b) => {
          try {
            if (!b.id) throw new Error("no brand id");
            const res = existingBrands.some((n) => n === b.name);
            if (res) throw new Error("brand already exists");
            await firebase.doRestoreBrand(b);
            return true;
          } catch (e) {
            console.log(e);
            return false;
          }
        })
      );
      downloadObject(
        "not_restored",
        brands.filter((_, i) => !didUploadAtIndex[i])
      );
    } catch (e) {
      console.log(e);
      setUploadFileErr(e.toString());
    }
  }, [uploadedFileObject, firebase, downloadObject]);

  return (
    <Wrapper>
      <Typography variant="h5">Backup</Typography>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudDownload />}
        onClick={handleDownloadProducts}
      >
        Produktdaten herunterladen
      </Button>
      <Typography variant="body1" color={"primary"}>
        {productsDownloadErr}
      </Typography>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudDownload />}
        onClick={handleDownloadClients}
      >
        Kundendaten herunterladen
      </Button>
      <Typography variant="body1" color={"primary"}>
        {clientsDownloadErr}
      </Typography>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudDownload />}
        onClick={handleDownloadBrands}
      >
        Markendaten herunterladen
      </Button>
      <Typography variant="body1" color={"primary"}>
        {brandsDownloadErr}
      </Typography>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudDownload />}
        onClick={handleDownloadCategories}
      >
        Kategoriendaten herunterladen
      </Button>
      <Typography variant="body1" color={"primary"}>
        {categoriesDownloadErr}
      </Typography>
      <Spacer />
      <Typography variant="body1" color={"secondary"}>
        Bitte lade die Bilder über die dafür vorgesehene Platform herunter:{" "}
        <a
          href={"https://console.cloud.google.com/storage/browser/"}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          https://console.cloud.google.com/storage/browser/
        </a>
      </Typography>
      <Spacer />
      <Typography variant="h5">Wiederherstellen</Typography>
      <Spacer />
      <Uploader>
        <input
          id={"fileInput"}
          type="file"
          onChange={(e) => handleUploadFile(e.target.files)}
          accept="text/json"
          multiple
        />
        <Button
          variant="contained"
          onClick={() => {
            document.getElementById("fileInput")?.click();
          }}
        >
          Daten hinzufügen
        </Button>
      </Uploader>
      <Spacer />
      <FilePreviewContainer>
        <pre id="JSON-preview" />
      </FilePreviewContainer>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudUpload />}
        onClick={handleUploadProducts}
        disabled={uploadedFileObject === null}
      >
        Produktdaten wiederherstellen
      </Button>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudUpload />}
        onClick={handleUploadClients}
        disabled={uploadedFileObject === null}
      >
        Kundendaten wiederherstellen
      </Button>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudUpload />}
        onClick={handleUploadBrands}
        disabled={uploadedFileObject === null}
      >
        Markendaten wiederherstellen
      </Button>
      <Spacer />
      <Button
        variant="contained"
        startIcon={<CloudUpload />}
        onClick={handleUploadCategories}
        disabled={uploadedFileObject === null}
      >
        Kategoriedaten wiederherstellen
      </Button>
      <Spacer />
      <Typography variant="body1" color={"primary"}>
        {uploadFileErr}
      </Typography>
    </Wrapper>
  );
};

export default Backup;
